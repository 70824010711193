<template lang="pug">
  .conversion__container
    h2.title.conversion__title ПЕРЕОБЛАДНАННЯ STARLINK ДЛЯ АВТОМОБІЛІВ
    .text.conversion__text
      p Drivelink - це портативна модифікація стандартного Starlink, яка дозволяє встановлювати супутниковий Інтернет "на колеса" та отримувати миттєвий доступ до мережі з мінімальною затримкою на всій території покриття супутників.
    ul.conversion__list
      li.conversion__item
        .conversion__img
          v-img(:src="MilitaryImg", alt="military")
        h3.title.conversion__subtitle DRIVELINK<br> для військових
      li.conversion__item.conversion__item--civil
        .conversion__img.conversion__img--civil
          v-img(:src="AutoImg", alt="auto")
        h3.title.conversion__subtitle DRIVELINK<br> для цивільних
        a(href="http://drivelink.com.ua").link.conversion__link.conversion__link--civil Перейти на сайт
</template>

<script>
import MilitaryImg from '@/assets/images/conversion/01.jpg'
import AutoImg from '@/assets/images/conversion/02.jpg'

export default {
  name: 'ConversionBlock',
  data () {
    return {
      MilitaryImg,
      AutoImg
    }
  }
}
</script>
